<template>
  <div class="frame">
		<div class="frame__left">
			<header class="header">
				<a class="header__logo" href="/">
					<img src="@/assets/img/logo.png" alt="">
				</a>
				<div class="header__phone">
					<div class="header__phone-desc">
						Номер телефона
					</div>
					<a class="header__phone-link" href="tel:+74993229001">
						+7 (499) 322-90-01
					</a>
				</div>
			</header>
			<div class="frame__left-content">
				<h1 class="title-main frame__title">
					Онлайн калькулятор
				</h1>
				<ul class="frame__list">
					<li class="frame__list-item">
						<a 
							class="frame__list-link" 
							href="#"
							:class="theme == 'auto'?'frame__list-link_active':''"
							@click.prevent="setTheme('auto')">
							Независимая автоэкспертиза
						</a>
					</li>
					<li class="frame__list-item">
						<a 
							class="frame__list-link" 
							href="#"
							:class="theme == 'apartment'?'frame__list-link_active':''"
							@click.prevent="setTheme('apartment')">
							Экспертиза ущерба квартиры
						</a>
					</li>
					<li class="frame__list-item">
						<a 
							class="frame__list-link" 
							href="#"
							:class="theme == 'legal'?'frame__list-link_active':''"
							@click.prevent="setTheme('legal')">
							Юридические услуги
						</a>
					</li>
				</ul>
			</div>
		</div>
		<div class="frame__right">
			<VueMultiselect 
				v-model="mobileSelect.value"
				:options="mobileSelect.values" 
				:preselect-first="true"
				@select="setTheme($event.value)" 
				:show-labels="false"
				:searchable="false"
				class="frame__mob-select" 
				label="label" 
				:allow-empty="false" 
				track-by="value"
				></VueMultiselect>
			<form class="frame__content calc" v-if="theme == 'auto'" @submit.prevent="presubmit">
				<div class="calc__title frame__question">
					Расчет стоимости оценки
				</div>
				<template
					v-for="(item, index) in auto.main"
					:key = index>

					<calc-elm 
						:item="item"
						@set-range = 'setRangeValue(item, $event)' 
						@check-update = "checkUpdate(item)"/>



					<div v-if = "item.items" class="calc__child">
						<template v-for = "(el, index) in item.items" :key="index">
							<calc-elm
								:item = "el"
								@open-modal = 'openModal($event)'
								@radio-update = "radioChange(el, item)"
								@inline-input = "inlineInput($event, el, item)"/>
						</template>
					</div>
				</template>

				<div class="calc__lined-text">
					<span class="calc__lined-text-inner">
						Дополнительные опции
					</span>
				</div>

				<template
					v-for="(item, index) in auto.extra"
					:key = index>

					<calc-elm 
						:item = "item" 
						@check-update = "checkUpdate(item)"/>

				</template>

				<div class="calc__line"></div>

				<div class="calc__final-price">
					<div class="calc__final-price-desc">
						Стоимость услуг
					</div>
					<div class="calc__final-price-value">
						{{auto.total}} руб
					</div>
				</div>
				<button class="btn-default calc__submit">
					Заказать оценку ущерба
				</button>
				<div class="calc__important">
					Данный расчет стоимости носит информационный характер и не является публичной офертой (ст. 437 ГК РФ).
				</div>
			</form>
			<form class="frame__content calc" v-if="theme == 'apartment'" @submit.prevent="presubmit">
				<div class="calc__title frame__question">
					Расчет стоимости услуг
				</div>
				<template
					v-for="(item, index) in apartment.main"
					:key = index>

					<calc-elm 
						:item="item"/>

					<div v-if = "item.items" class="calc__child">
						<template v-for = "(el, index) in item.items" :key="index">
							<calc-elm
								:item = "el"
								@check-update = "checkUpdate(el, item)"/>
						</template>
					</div>
				</template>

				<button @click="addApartment" type="button" class="calc__add-btn">
					+ Добавить комнату
				</button>

				<div class="calc__line"></div>

				<template
					v-for="(item, index) in apartment.extra"
					:key = index>

					<calc-elm 
						:item = "item" />

					<div v-if = "item.items" class="calc__child">
						<template v-for = "(el, index) in item.items" :key="index">
							<calc-elm
								:item = "el"
								@radio-update = "radioChange(el, item)"
								@inline-input = "inlineInput($event, el, item)"/>
						</template>
					</div>

				</template>

				<div class="calc__line"></div>

				<div class="calc__final-price">
					<div class="calc__final-price-desc">
						Стоимость услуг
					</div>
					<div class="calc__final-price-value">
						{{ apartment.total }} руб
					</div>
				</div>
				<button class="btn-default calc__submit">
					Заказать оценку ущерба
				</button>
				<div class="calc__important">
					Данный расчет стоимости носит информационный характер и не является публичной офертой (ст. 437 ГК РФ).
				</div>
			</form>
			<form class="frame__content calc" v-if="theme == 'legal'" @submit.prevent="presubmit">
				<div class="calc__title frame__question">
					Расчет стоимости услуг
				</div>

				<template
					v-for="(item, index) in legal.main"
					:key = index>

					<calc-elm 
						:item="item"
						@coef-change = "coefChange"
						@check-update = "checkUpdate(item)"/>

					<div v-if = "item.items" class="calc__child">
						<template v-for = "(el, index) in item.items" :key="index">
							<calc-elm
								:item = "el"
								@update-price-corrector="updatePriceCorrector($event, el)"
								@radio-update = "radioChange(el, item)"/>
						</template>
					</div>
				</template>

				<div class="calc__lined-text">
					<span class="calc__lined-text-inner">
						Дополнительные опции
					</span>
				</div>

				<template
					v-for="(item, index) in legal.extra"
					:key = index>

					<calc-elm 
						:item = "item" 
						@check-update = "checkUpdate(item)"/>

					<div v-if = "item.items" class="calc__child">
						<template v-for = "(el, index) in item.items" :key="index">
							<calc-elm
								:item = "el"
								@radio-update = "radioChange(el, item)"/>
						</template>
					</div>

				</template>

				<div class="calc__line"></div>

				<div class="calc__final-price">
					<div class="calc__final-price-desc">
						Стоимость услуг
					</div>
					<div class="calc__final-price-value">
						{{legal.total}} руб
					</div>
				</div>
				<button class="btn-default calc__submit">
					Оформить заявку
				</button>
				<div class="calc__important">
					Данный расчет стоимости носит информационный характер и не является публичной офертой (ст. 437 ГК РФ).
				</div>
			</form>
		</div>
	</div>
	<div class="modals">
		<div 
			class="modal modal-order"
			:class="modalOrder.active?'modal_active':''"
			id="modal-order">
			<div class="modal__overlay" @click="modalOrder.active = false"></div>
			<div class="modal__inner modal-order__inner">
				<button class="modal__close btn-close" @click="modalOrder.active = false"></button>
				<h2 class="modal-order__title">Оформление заявки</h2>
				<form class="modal-order__form" @submit.prevent="submit">
					<div class="modal-order__inputs">
						<label 
							class="modal-order__field" 
							:class="input.required?'modal-order__field_required':''"
							v-for="(input, index) in modalOrder.inputs" 
							:key="index">
							<div class="modal-order__label">
								{{ input.name }}
							</div>
							<input 
								v-if="input.type == 'input'"
								v-model="input.value" 
								class="modal-order__input"
								:class="input.invalid?'modal-order__input_invalid':''"
								v-maska
								:data-maska="input.mask"
								data-maska-eager
								@input="validate(input)"
								:required="input.required"
								type="text" >
							<textarea 
								v-if="input.type == 'textarea'"
								v-model="input.value"
								class="modal-order__textarea" ></textarea>

						</label>
					</div>
					<button class="btn-default modal-order__btn">
						Оформить заявку
					</button>
					<div class="modal-order__agree">
						<p>
							Мы гарантируем сохранность ваших персональных данных и не будет передавать ваши контакты третьим лицам.
						</p>
						<p>
							Нажимая на кнопку «Оформить заявку», вы даете согласие на <a href="#">обработку своих персональных данных</a>
						</p>
					</div>
				</form>
			</div>
		</div>
		<div 
			class="modal modal-success"
			:class="modalSuccess.active?'modal_active':''"
			id="modal-success">
			<div class="modal__overlay" @click="modalSuccess.active = false"></div>
			<div class="modal__inner modal-success__inner">
				<button class="modal__close btn-close" @click="modalSuccess.active = false"></button>
				<h2 class="modal-success__title">Заявка успешно принята</h2>
				<p class="modal-success__text">
					Наш менеджер свяжется с вами
				</p>
			</div>
		</div>
		<div 
			class="modal modal-we"
			:class="modalStation.active?'modal_active':''"
			id="modal-station">
			<div class="modal__overlay" @click="modalStation.active = false"></div>
			<div class="modal__inner modal-we__inner">
				<button class="modal__close btn-close" @click="modalStation.active = false"></button>
				<h2 class="modal-we__title">Лаборатория кузовного ремонта «РИХточка»</h2>
				<div class="modal-we__content">
					<div class="modal-we__content-row">
						<div class="modal-we__content-label">
							Фактический адрес:
						</div>
						<div class="modal-we__content-value">
							Мытищинский район, г. Мытищи, Волковское шоссе, проезд №497,
							вл.1 стр.2
						</div>
					</div>
					<div class="modal-we__content-row">
						<div class="modal-we__content-label">
							Телефон/факс:
						</div>
						<a class="modal-we__content-value" href="tel:+7 (495) 995-82-92">
							+7 (495) 995-82-92
						</a>
					</div>
					<div class="modal-we__content-row">
						<div class="modal-we__content-label">
							Время работы:
						</div>
						<div class="modal-we__content-value">
							Ежедневно с 9:00 до 21:00
						</div>
					</div>
				</div>
				<iframe 
					class="modal-we__map"
					:src="modalStation.active?'https://yandex.ru/map-widget/v1/?um=constructor%3A7a7f9cba2b9f89fcbc802cef350d7c6656dc730e58e7c45789834cfc668e3644&amp;source=constructor':''"></iframe>
			</div>
		</div>
		<div 
			class="modal modal-we"
			:class="modalOffice.active?'modal_active':''"
			id="modal-station">
			<div class="modal__overlay" @click="modalOffice.active = false"></div>
			<div class="modal__inner modal-we__inner">
				<button class="modal__close btn-close" @click="modalOffice.active = false"></button>
				<h2 class="modal-we__title">Офис «Хонест»</h2>
				<div class="modal-we__content">
					<div class="modal-we__content-row">
						<div class="modal-we__content-label">
							Фактический адрес:
						</div>
						<div class="modal-we__content-value">
							Рязанский проспект 109456, г. Москва, Рязанский проспект, д. 75, корп. 4, 2-я башня, 1-й этаж
						</div>
					</div>
					<div class="modal-we__content-row">
						<div class="modal-we__content-label">
							Телефон/факс:
						</div>
						<a class="modal-we__content-value" href="tel:+7 (495) 995-82-92">
							+7 (495) 995-82-92
						</a>
					</div>
					<div class="modal-we__content-row">
						<div class="modal-we__content-label">
							E-mail:
						</div>
						<a class="modal-we__content-value" href="mailto:info+agft955@damages.ru">
							info+agft955@damages.ru
						</a>
					</div>
					<div class="modal-we__content-row">
						<div class="modal-we__content-label">
							Время работы:
						</div>
						<div class="modal-we__content-value">
							пн–пт	9:00–20:00<br>
							сб	10:00–14:00<br>
							вс	выходной
						</div>
					</div>
				</div>
				<iframe 
					class="modal-we__map"
					:src="modalOffice.active?'https://yandex.ru/map-widget/v1/?um=constructor%3A7a7f9cba2b9f89fcbc802cef350d7c6656dc730e58e7c45789834cfc668e3644&amp;source=constructor':''"></iframe>
			</div>
		</div>
	</div>
</template>

<script>
import CalcElm from './CalcElm.vue'
import VueMultiselect from 'vue-multiselect'

export default {
	name: 'TheFrame',
	components: {
		CalcElm,
		VueMultiselect
	},
	data() {
		return {
			auto: {
				main: [
					{
						name: 'rate',
						label: 'Степень повреждения',
						type: 'range',
						tooltip: `Легкая - до 5 элементов<br>
							Средняя - до 10 элементов<br>
							Большая - до 30 элементов<br>
							Тотальная - свыше 30 элементов<br>

							*Повышающий коэффициент для грузового, спец. техники, эксклюзивного, раритетного  транспорта от 1 до 2. Детали уточняйте у менеджера.`,
						value: 0,
						values: [
							{
								name: 'Малая',
							},
							{
								name: 'Средняя',
							},
							{
								name: 'Большая',
							},
							{
								name: 'Тотальная',
							}
						]
					},
					{
						name: 'view',
						label: 'Осмотр и составление акта',
						type: 'checkbox',
						tooltip: `Акт осмотра - это документ, который составляется при осмотре автомобиля, в присутствии заинтересованных лиц.<br>
							В акте осмотра фиксируются все повреждения автомобиля, также там указываются способы их устранения (замена, или ремонт).`,
						active: true,
						uncheckable: true,
						items: [
							{
								name: 'view-item',
								label: `На тех. станции «Рихточка»`,
								modal: {
									name: 'г. Мытищи',
									link: 'Station'
								},
								type: 'radio',
								price: '500',
							},
							{
								name: 'view-item',
								label: 'У нашего офиса',
								modal: {
									name: 'Рязанский проспект',
									link: 'Office'
								},
								type: 'radio',
								price: '500',
								active: true
							},
							{
								name: 'view-item',
								label: 'Выезд специалиста в пределах МКАД',
								type: 'radio',
								price: '1 500',
							},
							{
								name: 'view-item',
								label: 'Выезд за МКАД',
								km: '',
								type: 'radio',
								minprice: '1 500',
								price: '1 500',
							},
						]
					},
					{
						name: 'calc',
						label: 'Расчёт и оформление',
						type: 'checkbox',
						tooltip: `Если в дальнейшем вы планируете обращаться в страховую компанию с претензией, или же в суд с иском о взыскании со страховой компании полной величины ущерба, то вам необходимо заключение эксперта-техника.`,
						items: [
							{
								name: 'calc-item',
								label: `Калькуляция`,
								type: 'radio',
								price: '2 500',
								prices: ['2 500', '3 000', '3 500', '4 000'],
								tooltip: `Калькуляция – определение затрат (стоимость услуг материалов и запасных частей) на восстановление поврежденного автомобиля.<br>
									Калькуляция не является Отчетом об оценке, и следовательно, документом доказательного значения.<br>
									На калькуляцию не распространяются требования Закона №135-ФЗ «Об оценочной деятельности».`,
							},
							{
								name: 'calc-item',
								label: 'Заключение эксперта-техника',
								type: 'radio',
								price: '4 000',
								prices: ['4 000', '4 500', '5 000', '5 500'],
								tooltip: `Письменное сообщение эксперта о результатах проведенного исследования по поставленным вопросам и обоснование представленных выводов.`,
							},
						]
					},
				],
				extra: [
					{
						name: 'auto-view2',
						label: 'Расчет утраты товарной стоимости',
						type: 'checkbox',
						price: '1 500',
						prices: ['1500', ' 1750', '2 000', '2 250'],
						tooltip: `Расчет Утраты Товарной Стоимости (УТС)<br>
							Восстановленный автомобиль после ДТП стоит дешевле такого же, но не поврежденного. Разница в их стоимости и есть УТС автомобиля.`,
					},
					{
						name: 'auto-view3',
						label: 'Расчет стоимости годных остатков',
						type: 'checkbox',
						price: '1 500',
						tooltip: `Годные остатки автомобиля после ДТП – это неповрежденные узлы и детали, годные к дальнейшей эксплуатации и которые можно демонтировать с поврежденного автомобиля и продать.`,
					},
					{
						name: 'auto-view3',
						label: 'Определение рыночной стоимости',
						type: 'checkbox',
						price: '1 500',
					},
					{
						name: 'auto-view3',
						label: 'Отправка телеграммы',
						type: 'checkbox',
						price: '700',
						tooltip: `Для ведения дела вам может понадобиться отправка 1-2 телеграмм.`,
					},
				],
				total: 0
			},
			apartment: {
				main: [
					{
						name: 'rooms',
						label: 'Выберите поврежденные элементы',
						type: 'text',
						active: true,
						count: 1,
						items: [
							{
								name: 'rooms-item',
								label: 'Прихожая',
								type: 'checkbox',
								price: '2 000'
							},
							{
								name: 'rooms-item',
								label: 'Коридор',
								type: 'checkbox',
								price: '2 000'
							},
							{
								name: 'rooms-item',
								label: 'Ванная комната',
								type: 'checkbox',
								price: '2 000',
								active: true
							},
							{
								name: 'rooms-item',
								label: 'Туалет',
								type: 'checkbox',
								price: '2 000'
							},
							{
								name: 'rooms-item',
								label: 'Кухня',
								type: 'checkbox',
								price: '2000'
							},
							{
								name: 'rooms-item',
								label: 'Комната 1',
								type: 'checkbox',
								price: '2 000'
							},
						]
					},
				],
				extra: [
					{
						name: 'place',
						label: 'Где вы находитесь?',
						type: 'text',
						items: [
							{
								name: 'place-item',
								label: 'Москва, в пределах МКАД',
								type: 'radio',
								price: '0'
							},
							{
								name: 'place-item',
								label: 'За пределами МКАД',
								type: 'radio',
								km: '',
								minprice: '1 500',
								price: '1 500',
							},
						]
					},
				],
				total: 0
			},
			legal: {
				main: [
					{
						name: 'docs',
						label: 'Подача документов в страховую компанию или РСА',
						type: 'checkbox',
						price: '6 000',
						tooltip: `Мы экономим ваше время и гарантируем правильность оформления всех документов.<br>
							При выборе этой услуги, мы вместо вас:<br>
							- Поедем в страховую компанию<br>
							- Отстоим в очередях<br>
							- Сдадим документы, согласуем дату и время осмотра<br>
							- Проконтролируем ход рассмотрения документов<br>

							В случае, если в установленные в законе сроки выплата не будет произведена, мы сообщаем вам об этом и обратимся в суд.`
					},
					{
						name: 'representative',
						label: 'Представление интересов в суде',
						type: 'checkbox',
						active: true,
						coef: [
							{
								name: 'г.Москвы',
								value: '0',
								active: true
							},
							{
								name: 'Московской области',
								value: '1 000'
							},
						],
						items: [
							{
								name: 'representative-item',
								label: 'В одном судебном заседании',
								type: 'radio',
								minPrice: '6 000',
								price: '6 000',
								coefSlave: true,
								tooltip: `При заказе вы получаете:<br>
									- подготовку правовой позиции;<br>
									- участие в одном судебном заседании.`, 
								active: true
							},
							{
								name: 'representative-item',
								label: 'От заявления до заседания',
								type: 'radio',
								price: '15 000',
								tooltip: `Данная услуга предоставляется только для исков стоимостью от 50 000 до 120 000 рублей.<br>
									При заказе вы получаете:<br>
									- подготовку искового заявления;<br>
									- подача в суд;<br>
									- участие в предварительном и одном судебном заседании.`
							},
							{
								name: 'representative-item',
								label: 'Ведение дела «под ключ», при цене иска: до 50 тыс.руб.',
								type: 'radio',
								price: '15 000',
								priceCorrector : {
									index: '0',
									items: [
										{
											label: 'до 50 тыс.руб.',
											point: '50',
											value: '15 000'
										},
										{
											label: '50—70 тыс.руб.',
											point: '50-70',
											value: '20 000'
										},
										{
											label: '70—100 тыс.руб.',
											point: '70-100',
											value: '25 000'
										},
										{
											label: '100—150 тыс.руб.',
											point: '100-150',
											value: '30 000'
										},
										{
											label: '150—350 тыс.руб.',
											point: '150-350',
											value: '40 000'
										},
										{
											label: 'свыше 350 тыс.руб.',
											point: '350',
											value: '50 000'
										}
									],
								},
								tooltip: `При заказе вы получаете:<br>
									- Подготовку искового заявления;<br>
									- Подача иска в суд;<br>
									- Участие во всех судебных заседаниях в суде первой инстанции;<br>
									- Получение решения и исполнительного листа.`
							},
						]
					},
				],
				extra: [
					{
						name: 'telegramma',
						label: 'Отправка телеграммы',
						type: 'checkbox',
						price: '700',
						tooltip: `Вам понадобится эта услуга, только если ответчиком выступает физическое лицо!<br>
							Как правило, при рассмотрении гражданских дел мы отправляем 1-2 телеграммы.`
					},
					{
						name: 'list',
						label: 'Получение исполнительного листа и предъявление его в',
						type: 'checkbox',
						items: [
							{
								name: 'list-item',
								label: 'Банк',
								type: 'radio',
								price: '3000'
							},
							{
								name: 'list-item',
								label: 'Службу судебных приставов',
								type: 'radio',
								price: '3 000',
							},
							{
								name: 'list-item',
								label: 'Обе инстанции',
								type: 'radio',
								price: '5 000',
							},
						]
					},
				],
				total: 0
			},
			modalOrder: {
				active: false,
				inputs: [
					{
						name: 'Имя',
						value: '',
						type: 'input'
					},
					{
						name: 'Телефон',
						value: '',
						mask: '+7 (###) ###-##-##',
						type: 'input',
						required: true
					},
					{
						name: 'E-mail',
						value: '',
						type: 'input'
					},
					{
						name: 'Комментарий',
						value: '',
						type: 'textarea'
					},
				]
			},
			modalStation: {
				active: false
			},
			modalOffice: {
				active: false
			},
			modalSuccess: {
				active: false
			},
			mobileSelect: {
				values: [
					{
						label: 'Независимая автоэкспертиза',
						value: 'auto'
					},
					{
						label: 'Экспертиза ущерба квартиры',
						value: 'apartment'
					}, 
					{
						label: 'Юридические услуги',
						value: 'legal'
					}
				],
				value: {
					label: 'Независимая автоэкспертиза',
					value: 'auto'
				},
			},
			theme: 'auto',
		}
	},
	mounted() {
		this.calcTotalPrice();
	},
	methods: {
		checkUpdate(item, parent = null) {
			item.active = !item.active;

			if (parent&&parent.items) {
				parent.active = false;

				parent.items.forEach(el => {
					if (el.active) {
						parent.active = true;
						return;
					}
				})
			}

			if (item.uncheckable) {
				item.active = true;
			}

			if (item.items) {
				item.items.forEach(element => {
					element.active = false;
				});
				if (item.active) {
					item.items[0].active = true;
				}
			}

			this.calcTotalPrice();
		},
		radioChange(item, parent = null) {
			if (parent&&parent.items) {
				parent.active = true;

				parent.items.forEach(element => {
					element.active = false;
				});
			}

			item.active = true;

			this.calcTotalPrice();
		},
		calcTotalPrice() {
			let total = 0;

			this[this.theme].main.forEach(element => {
				if (element.active) {
					if (element.items) {
						element.items.forEach(child => {
							if (child.active) {
								total += parseInt(child.price.replace(new RegExp(" ",'g'), '').replace(new RegExp(" ",'g'), ''));
							}
						})
					}
					else {
						if (element.price) {
							total += parseInt(element.price.replace(new RegExp(" ",'g'), '').replace(new RegExp(" ",'g'), ''));
						}
					}
				}
			});

			this[this.theme].extra.forEach(element => {
				if (element.active) {
					if (element.items) {
						element.items.forEach(child => {
							if (child.active) {
								total += parseInt(child.price.replace(new RegExp(" ",'g'), '').replace(new RegExp(" ",'g'), ''));
							}
						})
					}
					else {
						if (element.price) {
							total += parseInt(element.price.replace(new RegExp(" ",'g'), '').replace(new RegExp(" ",'g'), ''));
						}
					}
				}
			});

			const format = num => new Intl.NumberFormat('ru-RU').format(num);

			this[this.theme].total = format(total);
		},
		damageRate() {
			this.auto.main.forEach(item => {
				let index = this.auto.main[0].value;

				if (item.items) {
					item.items.forEach(el => {
						if (el.prices) {
							el.price = el.prices[index];
						}
					})
				}
			})

			this.auto.extra.forEach(item => {
				let index = this.auto.main[0].value;

				if (item.prices) {
					item.price = item.prices[index];
				}
			})
		},
		openModal(event) {
			this['modal' + event].active = true;
		},
		setRangeValue(item, value) {
			item.value = value;
			this.damageRate();
			this.calcTotalPrice();
		},
		inlineInput(event, el, item) {
			const format = num => new Intl.NumberFormat('ru-RU').format(num);

			if (item.items) {
				item.items.forEach(element => {
					element.active = false;
				});
			}
			item.active = true;
			el.active = true;
			el.km = event.target.value;


			el.price = format(parseInt(el.minprice.replace(new RegExp(" ",'g'), '').replace(new RegExp(" ",'g'), '')) + Math.ceil(el.km / 10) * 300);

			this.calcTotalPrice();
		},
		addApartment() {
			this.apartment.main[0].items.push({
				name: 'place-item',
				label: 'Комната ' + ++this.apartment.main[0].count,
				type: 'checkbox',
				price: '2000'
			})
		},
		validate(input) {
			if (input.required) {
				if (input.name == 'Телефон') {
					input.invalid = false;

					if (input.value.length < 18) {
						input.invalid = true;
					}
				}
			}
		},
		presubmit() {
			this.modalOrder.active = true;
		},
		submit() {
			let invalid = false;

			this.modalOrder.inputs.forEach(el => {
				if (el.required && el.invalid) {
					invalid = true;
				}
			})

			if (invalid) return;

			let FD = new FormData();
			
			FD.append('calc', this.theme)

			this[this.theme].main.forEach(element => {
				if (element.active) {
					FD.append(element.label, element.price)

					if (element.items) {
						element.items.forEach(child => {
							if (child.active) {
								FD.append(child.label, child.price)

								if (child.priceCorrector) {
									console.log(child.priceCorrector.items[child.priceCorrector.index].label);
									FD.append('priceCorrector', child.priceCorrector.items[child.priceCorrector.index].label)
								}
							}
						})
					}

					if (element.coef) {
						element.coef.forEach(child => {
							if (child.active) {
								FD.append(child.label, child.name)
							}
						})
					}
				}
			});

			this[this.theme].extra.forEach(element => {
				if (element.active) {
					FD.append(element.label, element.price)

					if (element.items) {
						element.items.forEach(child => {
							if (child.active) {
								FD.append(child.label, child.price)
							}
						})
					}
				}
			});


			FD.append('total', this[this.theme].total)

			this.modalOrder.inputs.forEach(element => {
				if (element.value.length) {
					FD.append(element.name, element.value)
				}
			});
			
			let object = {};
			FD.forEach((value, key) => object[key] = value);
			let json = JSON.stringify(object);


			// console.log(json);
			fetch('/', {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: json
			})
			.then(response => {
				console.log(response);
				this.modalOrder.active = false;
				this.modalSuccess.active = true;
			})
			.catch(error => {
				console.log(error);
			});
		},
		setTheme(theme) {
			this.theme = theme;
			this.calcTotalPrice();
		},
		coefChange(el, item) {
			item.coef.forEach(element => {
				element.active = false;
			})

			el.active = true;

			const format = num => new Intl.NumberFormat('ru-RU').format(num);

			item.items.forEach(element => {
				if (element.coefSlave) {
					element.price = format(parseInt(element.minPrice.replace(new RegExp(" ",'g'), '').replace(new RegExp(" ",'g'), '')) + parseInt(el.value.replace(new RegExp(" ",'g'), '').replace(new RegExp(" ",'g'), '')));
				}
			})

			this.calcTotalPrice();
		},
		updatePriceCorrector(event, el) {
			el.price = el.priceCorrector.items[event].value;
			el.priceCorrector.index = event;

			this.calcTotalPrice();
		}
	}
}
</script>

<style scoped>
</style>

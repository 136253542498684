<template>
	<div 
		class="calc__elm"
		:class="item.active?'calc__elm_active':''"
		v-if=" item.type == 'text' ">
		{{ item.label }}
	</div>

	<div 
		class="calc__elm"
		:class="item.active?'calc__elm_active':''"
		:type="range"
		v-if=" item.type == 'range' ">
						
		<div class="calc__elm-name">
			{{ item.label }} <span class="tooltip" v-if="item.tooltip" v-tippy="{content: item.tooltip, allowHTML: true}"></span>
		</div>

		<div class="calc__elm-range">
			<Slider
				class="from-md"
				:max=item.values.length-1
				:tooltips=false
				:value="item.value"
				@change="setRangeValue"
				/>

			<div class="calc__elm-range-values from-md">
				<div
					class="calc__elm-range-value"
					v-for="(el, index) in item.values"
					:key = index>
					{{ el.name }}
				</div>
			</div>

			<div class="calc__elm-range-mob-items to-md">
				<label 
					class="calc__elm-range-mob-item custom-radio"
					v-for="(el, index) in item.values"
					:key = index>

					<input class="custom-radio__input" 
						type="radio" 
						:name="item.name" 
						:checked="index==item.value" 
						@change = " $emit('setRange', index) ">
					<span class="custom-radio__fake">
						{{ el.name }}
					</span>
				</label>
			</div>
		</div>
	</div>


	<div 
		class="calc__elm"
		:class="item.active?'calc__elm_active':''"
		:type="checkbox"
		v-if=" item.type == 'checkbox' ">
		<label class="custom-checkbox">
			<input class="custom-checkbox__input" type="checkbox" :checked="item.active" @change="checkUpdate(item, $event)">
			<span class="custom-checkbox__fake">
				{{ item.label }} <span class="tooltip" v-if="item.tooltip" v-tippy="{content: item.tooltip, allowHTML: true}"></span>
			</span>
		</label>
		
		<div v-if="item.price" class="calc__elm-price">
			{{item.price}} руб.
		</div>
	</div>

	<div 
		class="calc__elm"
		:class="item.active?'calc__elm_active':''"
		:type="radio"
		v-if=" item.type == 'radio' ">
		<label class="custom-radio">
			<input class="custom-radio__input" type="radio" :name="item.name" :checked="item.active" @change="$emit('radioUpdate')">
			<span class="custom-radio__fake">
				{{ item.label }} <span class="tooltip" v-if="item.tooltip" v-tippy="{content: item.tooltip, allowHTML: true}"></span>
			</span>
		</label>

		<a 
			:href="item.modal.link"
			@click.prevent="$emit('openModal', item.modal.link)"
			v-if="item.modal">
			<br>{{ item.modal.name }}
		</a>


		<span
			v-if="item.km >= 0">
			<input class="calc__elm-child-input" type="text" :value="item.km" @input="$emit('inlineInput', $event)"> км
		</span>
		
		<div v-if="item.price" class="calc__elm-price">
			<template v-if="item.price != '0'">
				{{ item.price }} руб.
			</template>
			<template v-else>
				Бесплатно
			</template>
		</div>

		<div v-if="item.priceCorrector" class="calc__elm-price-corrector">
			<Slider
				:max=item.priceCorrector.items.length-1
				:value="item.priceCorrector.index"
				:options="item.priceCorrector.items"
				:tooltips=false
				@change="$emit('updatePriceCorrector', $event, item)"
				/>
			<div class="calc__elm-price-corrector-values">
				<div class="calc__elm-price-corrector-value" v-for="(el, index) of item.priceCorrector.items" :key="index">
						{{ el.point }}
				</div>
			</div>
		</div>
	</div>

	<div 
		class="calc__elm"
		v-if="item.coef">
		<div class="calc__elm-coefs">
			<label v-for="(el, index) of item.coef" :key="index" class="calc__elm-coef">
				<input type="radio" name="item.name" class="calc__elm-coef-input" :checked="el.active" @change="$emit('coefChange', el, item)">
				<span class="calc__elm-coef-fake">
					{{ el.name }}
				</span>
			</label>
		</div>
	</div>
</template>

<script>
import Slider from '@vueform/slider'

export default {
	name: 'CalcElm',
	props: ['item'],
	components: {
		Slider
	},
	methods: {
		setRangeValue(value) {
			this.$emit('setRange', value);
		},
		checkUpdate(item, event) {
			event.target.checked = item.active;

			this.$emit('checkUpdate');
		}
	},
	// computed: {
	// 	getKmPrice() {
	// 		const format = num => new Intl.NumberFormat('ru-RU').format(num);

	// 		return format(parseInt(this.item.minprice.replace(" ", '')) + Math.floor(this.item.km / 10) * 300);
	// 	}
	// }
}
</script>